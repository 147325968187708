import React, { useState } from "react";
import SettingNavSection from "../../pages/Settings/SettingsNavSection";
import Header from "./Header";
import data from "../../utilities/constants";
import useResponsive from "./../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Conversations from "../../pages/Messaging/Conversations";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Container from "../../pages/Messaging/Container";
const SubSidebar = ({ archived }) => {
  const { pathname } = useLocation();
  const { user, status } = useSelector((state) => state.user);
  const isDesktop = useResponsive("up", "lg");
  const [activeFilterCardsMessagesButton, setActiveFilterCardsMessagesButton] =
    useState({
      id: data.FILTER_CARDS_MESSAGES[0].id,
      title: data.FILTER_CARDS_MESSAGES[0].title,
    });
  const [choosenSegment, setChoosenSegment] = useState(null);
  const [assignedConversations, setAssignedConversations] = useState(false);
  const { i18n } = useTranslation();

  const SettingsSubSideBar = styled("div")(({ theme }) => ({
    flexGrow: 1,
    height: "100%",
    backgroundColor: "#fafafa",
    height: "100vh",
    width: !pathname.includes("/settings") && "22%!important",
  }));
  const sidebardata =
    user?.role?.code === "OPERATOR"
      ? data.SETTINGS.filter((item) => {
          return ![
            "/settings/users",
            "/settings/website-configuration",
            "/settings/departments",
          ].includes(item?.path);
        })
      : data.SETTINGS;
  return (
    <>
      {pathname.includes("/messaging") ? (
        <div
          className="subsidebar-wrapper"
          style={
            i18n.language === "ar"
              ? { borderLeft: "1px solid #DCDCDD", width: "50px !important" }
              : { borderRight: "1px solid #DCDCDD" }
          }
        >
          <Header
            activeFilterCardsMessagesButton={activeFilterCardsMessagesButton}
            setActiveFilterCardsMessagesButton={
              setActiveFilterCardsMessagesButton
            }
            setChoosenSegment={setChoosenSegment}
            choosenSegment={choosenSegment}
            user={user}
          />
          <Conversations
            user={user}
            choosenSegment={choosenSegment}
            assignedConversations={assignedConversations}
            setAssignedConversations={setAssignedConversations}
          />
        </div>
      ) : (
        isDesktop &&
        !pathname.includes("/archived") && (
          <>
            {pathname.includes("/settings") ? (
              <SettingsSubSideBar
                className="subsidebar-wrapper settings-subsidebar"
                style={
                  i18n.language === "ar"
                    ? { borderLeft: "1px solid #DCDCDD" }
                    : { borderRight: "1px solid #DCDCDD" }
                }
              >
                <Header />
                <div className="sections-wrapper">
                  {sidebardata.map((item) =>
                    status === "loading" ? null : (
                      <SettingNavSection item={item} key={item.id} />
                    )
                  )}
                </div>
              </SettingsSubSideBar>
            ) : (
              <SettingsSubSideBar
                className="subsidebar-wrapper"
                style={
                  i18n.language === "ar"
                    ? { borderLeft: "1px solid #DCDCDD" }
                    : { borderRight: "1px solid #DCDCDD" }
                }
              >
                <Header user={user} />
                <div className="sections-wrapper">
                  {(pathname.includes("/todo-list")
                    ? data.TODOLIST_ROUTES
                    : data.HELPDESK_ROUTES
                  ).map((item, index) =>
                    pathname.includes("/todo-list") ? (
                      (() => {
                        return (
                          (((index === 0 || index === 2) &&
                            user?.role?.code !== "OPERATOR") ||
                            index === 1) &&
                          (status === "loading" ? null : (
                            <SettingNavSection item={item} key={item.id} />
                          ))
                        );
                      })()
                    ) : (
                      <SettingNavSection item={item} key={item.id} />
                    )
                  )}
                </div>
              </SettingsSubSideBar>
            )}
          </>
        )
      )}
      {pathname.includes("/archived") && (
        <SettingsSubSideBar
          // className="subsidebar-wrapper"
          style={
            i18n.language === "ar"
              ? { borderLeft: "1px solid #DCDCDD" }
              : { borderRight: "1px solid #DCDCDD" }
          }
        >
          <Header user={user} />
          <div className="archived-section-wrapper">
            <Conversations user={user} archived />
            <Container user={user} archived />
          </div>
        </SettingsSubSideBar>
      )}
    </>
  );
};

export default SubSidebar;
