import { List, ListItemButton, Popover, Avatar } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOperators } from "../../slices/operaters";
const Suggestions = ({ handleClick, anchorEl, open, handlePopoverClose }) => {
  const dispatch = useDispatch();
  const { operators } = useSelector((state) => state.operators);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    operators?.length === 0 &&
      user.websiteID &&
      open &&
      dispatch(fetchOperators({ websiteID: user.websiteID, query: "" }));
  }, [open, operators?.length === 0, user.websiteID]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <List sx={{ mt: 1 }}>
        {operators.map((item) => {
          return (
            <ListItemButton
              onClick={() => {
                handleClick("@" + item.firstName + " " + item.lastName);
                handlePopoverClose();
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <Avatar
                  alt={item.firstName + "" + item.lastName}
                  src={item.avatar}
                />

                <span style={{ marginTop: "10px" }}>
                  {item.firstName + " " + item.lastName}
                </span>
              </div>
            </ListItemButton>
          );
        })}
      </List>
    </Popover>
  );
};

export default Suggestions;
